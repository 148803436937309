import { useDispatch, useSelector } from "react-redux";
import { selectMapInfo, setMapCoordinates, setMapAddress, addMapParcelle, setMapParcelles, setBrouillon, setInvitationToken, setMapCadastres, setMapSelectedCadastres } from "../store/mapInfoSlice";
import { selectCarto, setMutations as setMutationsStore, setSelectedParcelle as setSelectedparcelleStore } from "../store/cartoSlice";

export const useCarto = () => {
    const dispatch = useDispatch();
    const carto = useSelector(selectCarto);

    const setMutations = (mutations) => {
        dispatch(setMutationsStore(mutations));
    }

    const setSelectedParcelle = (parcelle) => {
        dispatch(setSelectedparcelleStore(parcelle));
    }

    return {
        carto,
        setMutations,
        setSelectedParcelle
    }
}

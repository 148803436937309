//Redux
import { createSlice } from "@reduxjs/toolkit";
import { COORDINATES_DEFAULT } from "../config/constants";
import { set } from "react-hook-form";

const initialState = {
	centerCoordinates: {
        latitude: COORDINATES_DEFAULT.latitude,
        longitude: COORDINATES_DEFAULT.longitude,
        zoom: COORDINATES_DEFAULT.zoom
    },
    maxBounds: null,
    address: {
        id: null,
        label: null,
        postcode: null,
        housenumber: null,
        street: null,
        city: null,
        citycode: null,
        score: null,
        coordinates: {
            latitude: null,
            longitude: null
        }
    },
    parcelles: [],
    cadastres: [],
    selectedCadastres: [],
    brouillon: null,
    invitationToken: null
};

/**
 * The Map slice
 */
export const mapInfoSlice = createSlice({
	name: 'mapInfo',
	initialState,
	reducers: {
        setMapInfo: (state, action) => {
            return state = action.payload;
        },
        setMapBounds: (state, action) => {
            state.maxBounds = action.payload;
        },
        setMapCoordinates: (state, action) => {
            state.centerCoordinates = action.payload;
        },
        setMapAddress: (state, action) => {
            state.address = action.payload;
        },
        setBrouillon: (state, action) => {
            state.brouillon = action.payload;
        },
        addMapParcelle: (state, action) => {
            state.parcelles.push(action.payload);
        },
        setMapParcelles: (state, action) => {
            state.parcelles = action.payload;
        },
        addMapCadastre: (state, action) => {
            state.cadastres.push(action.payload);
        },
        setMapCadastres: (state, action) => {
            state.cadastres = action.payload;
        },
        addMapSelectedCadastre: (state, action) => {
            state.selectedCadastres.push(action.payload);
        },
        setMapSelectedCadastres: (state, action) => {
            state.selectedCadastres = action.payload;
        },
        setInvitationToken: (state, action) => {
            state.invitationToken = action.payload;
        },
	}
});

export const { setMapInfo, setMapBounds, setMapCoordinates, setMapAddress, addMapParcelle, setMapParcelles, addMapCadastre, setMapCadastres, addMapSelectedCadastre, setMapSelectedCadastres, setBrouillon, setInvitationToken } = mapInfoSlice.actions;
export const selectMapInfo = (state: any) => state.mapInfo;

export default mapInfoSlice.reducer;

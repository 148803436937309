//React
import { useEffect, useState } from "react";

//Hooks
import { useApi } from "../hooks/useApi";
import { useMapInfo } from "../hooks/useMapInfo";
import { useMapUtil } from "../hooks/useMapUtil";

//Leaflet & geo
import { GeoJSON, Marker, useMapEvents } from 'react-leaflet';
import { LatLng, LatLngBounds } from "leaflet";

function MapRegionLayer() {
    /**
     * Hooks
     */
    const { mapInfo, centerMap } = useMapInfo();
    const { createLoader, deleteLoader, isLoader } = useMapUtil();
    const { getRegionsGeoJSON } = useApi();
    const mapEvent = useMapEvents({
        //Detect zoom change
        zoomend: () => {
            setActualZoom(mapEvent.getZoom());
        },
        //Detect move change
        moveend: () => {
            setCenter(mapEvent.getCenter());
            setBounds(mapEvent.getBounds());
        }
    });

    /**
     * States
     */
    const [regions, setRegions] = useState(null);
    const [layerKey, setLayerKey] = useState(0); //Pour forcer le rechargement du layer (voir useEffect)
    const [actualZoom, setActualZoom] = useState(0);
    const [center, setCenter] = useState<LatLng>(null);
    const [bounds, setBounds] = useState<LatLngBounds>(null);

    /**
     * useEffects
     */
    useEffect(() => {
        setLayerKey(layerKey + 1);
    }, [regions]);

    useEffect(() => {
        if(actualZoom <= 8 && !isLoader('regions') && !regions) {
            createLoader('regions', 'Chargement des régions...');
            getRegionsGeoJSON()
                .then((data) => {
                    setRegions(data);
                    deleteLoader('regions');
                })
                .catch((error) => {
                    console.error(error);
                    deleteLoader('regions');
                });
        }
    }, [actualZoom, center, bounds]);

    if(!regions || actualZoom > 8) return (
        <>
        </>
    );

    /**
     * Handlers
     */
    const handleClickOnRegion = (e) => {
        //Zoom sur la région
        //On récupère les coordonnées du point central de la région
        let regionBounds = e.target.getBounds();
        let centerLon = regionBounds.getCenter().lng;
        let centerLat = regionBounds.getCenter().lat;
        
        centerMap({
            longitude: centerLon,
            latitude: centerLat
        }, 9);
    }

    const onEachRegion = (feature, layer) => {
        layer.on({
            click: handleClickOnRegion
        });
    }
    
    return (
        <>
            <GeoJSON
                key={layerKey}
                data={regions}
                style={{color: 'white'}}
                onEachFeature={onEachRegion}/>
        </>
    );
}

export default MapRegionLayer;
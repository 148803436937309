//React
import { useEffect, useState } from "react";

//Hooks
import { useApi } from "../hooks/useApi";
import { useMapInfo } from "../hooks/useMapInfo";
import { useMapUtil } from "../hooks/useMapUtil";

//Leaflet & geo
import { GeoJSON, Marker, useMapEvents } from 'react-leaflet';
import { LatLng, LatLngBounds } from "leaflet";
import { geoExpandBounds } from "../helpers/GeoHelper";

function MapDepartementLayer() {
    /**
     * Hooks
     */
    const { mapInfo, centerMap } = useMapInfo();
    const { createLoader, deleteLoader, isLoader } = useMapUtil();
    const { getDepartementsGeoJSON } = useApi();
    const mapEvent = useMapEvents({
        //Detect zoom change
        zoomend: () => {
            setActualZoom(mapEvent.getZoom());
        },
        //Detect move change
        moveend: () => {
            setCenter(mapEvent.getCenter());
            setBounds(mapEvent.getBounds());
        }
    });

    /**
     * States
     */
    const [departements, setDepartements] = useState(null);
    const [layerKey, setLayerKey] = useState(0); //Pour forcer le rechargement du layer (voir useEffect)
    const [actualZoom, setActualZoom] = useState(0);
    const [center, setCenter] = useState<LatLng>(null);
    const [bounds, setBounds] = useState<LatLngBounds>(null);

    /**
     * useEffects
     */
    useEffect(() => {
        setLayerKey(layerKey + 1);
    }, [departements]);

    useEffect(() => {
        if(actualZoom == 9 && !isLoader('departements')) {
            createLoader('departements', 'Chargement des départements...');
            let newBounds = geoExpandBounds(bounds, 300);
            getDepartementsGeoJSON(null, newBounds.toBBoxString())
                .then((data) => {
                    //On fusionne les features pour éviter les doublons
                    let features = departements==null?[]:departements.features;
                    data.features.forEach((feature) => {
                        let isAlreadyPresent = false;
                        for(let i = 0; i < features.length; i++) {
                            if(features[i].properties.code == feature.properties.code) isAlreadyPresent = true;
                        }
                        if(!isAlreadyPresent) {
                            features = [...features, feature];
                        }
                    });
                    setDepartements({
                        type: "FeatureCollection",
                        features: features
                    });
                    deleteLoader('departements');
                })
                .catch((error) => {
                    console.error(error);
                    deleteLoader('departements');
                });
        }
    }, [actualZoom, center, bounds]);

    if(!departements || actualZoom != 9) return (
        <>
        </>
    );

    /**
     * Handlers
     */
    const handleClickOnDepartement = (e) => {
        let departementBounds = e.target.getBounds();
        let centerLon = departementBounds.getCenter().lng;
        let centerLat = departementBounds.getCenter().lat;
        
        centerMap({
            longitude: centerLon,
            latitude: centerLat
        }, 10);
    }

    const onEachDepartement = (feature, layer) => {
        layer.on({
            click: handleClickOnDepartement
        });
    }
    
    return (
        <>
            <GeoJSON
                key={layerKey}
                data={departements}
                style={{color: 'white'}}
                onEachFeature={onEachDepartement}/>
        </>
    );
}

export default MapDepartementLayer;
//Redux
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	mutations: [],
    dossierParcelles: [],
    selectedParcelle: null,
};

/**
 * The Carto slice
 */
export const cartoSlice = createSlice({
	name: 'carto',
	initialState,
	reducers: {
        setCarto: (state, action) => {
            return state = action.payload;
        },
        addMutation: (state, action) => {
            state.mutations.push(action.payload);
        },
        setMutations: (state, action) => {
            state.mutations = action.payload;
        },
        addDossierParcelle: (state, action) => {
            state.dossierParcelles.push(action.payload);
        },
        setDossierParcelles: (state, action) => {
            state.dossierParcelles = action.payload;
        },
        setSelectedParcelle: (state, action) => {
            state.selectedParcelle = action.payload;
        }
	}
});

export const { setCarto, addMutation, setMutations, addDossierParcelle, setDossierParcelles, setSelectedParcelle } = cartoSlice.actions;
export const selectCarto = (state: any) => state.carto;

export default cartoSlice.reducer;

//Models
import { FeatureCollection, GeoJsonObject } from "geojson";
import { Brouillon } from "../models/Brouillon";
import { api } from "../helpers/api";
import { Dossier } from "../models/Dossier";
import { format } from "path";

export const useApi = () => {
    const getRegionsGeoJSON = () => {
        return new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL+'/tools/geo/regions')
                .then((response) => {
                    if(response.status >= 400) response.json().then((error) => reject(error));
                    else resolve(response.json());
                });
        });
    }
    
    const getDepartementsGeoJSON = (region = null, boundbox = null) => {
        return new Promise<FeatureCollection>((resolve, reject) => {
            let urlParams = '?format=geojson';
            if(region) urlParams += '&region=' + region;
            if(boundbox) urlParams += '&boundbox=' + boundbox;
            fetch(process.env.REACT_APP_API_URL+'/tools/geo/departements' + urlParams)
                .then((response) => {
                    if(response.status >= 400) response.json().then((error) => reject(error));
                    else resolve(response.json() as any as FeatureCollection);
                });
        });
    }
    
    const getCommunesGeoJSON = (region = null, departement = null, boundbox = null) => {
        return new Promise<FeatureCollection>((resolve, reject) => {
            let urlParams = '?format=geojson';
            if(region) urlParams += '&region=' + region;
            if(region) urlParams += '&departement=' + departement;
            if(boundbox) urlParams += '&boundbox=' + boundbox;
            fetch(process.env.REACT_APP_API_URL+'/tools/geo/communes' + urlParams)
                .then((response) => {
                    if(response.status >= 400) response.json().then((error) => reject(error));
                    else resolve(response.json() as any as FeatureCollection);
                });
        });
    }
    
    const getCadastreGeoJSON = (codeInsee = null, boundbox = null, abortSignal = null) => {
        return new Promise<FeatureCollection>((resolve, reject) => {
            let params = {
                params: {
                    format: 'geojson',
                }
            };

            if(abortSignal) params['signal'] = abortSignal;
            if(codeInsee) params['params']['commune'] = codeInsee;
            if(boundbox) params['params']['boundbox'] = boundbox;

            api.get('/tools/geo/cadastres', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    
    const getMutationGeoJSON = (codeInsee = null, boundbox = null) => {
        return new Promise<FeatureCollection>((resolve, reject) => {
            let urlParams = '?format=geojson';
            if(codeInsee) urlParams += '&commune=' + codeInsee;
            if(boundbox) urlParams += '&boundbox=' + boundbox;
            fetch(process.env.REACT_APP_API_URL+'/tools/geo/mutations' + urlParams)
                .then((response) => {
                    if(response.status >= 400) response.json().then((error) => reject(error));
                    else resolve(response.json() as any as FeatureCollection);
                });
        });
    }

    const getDocumentUrbanismeGeoJSON = (codeInsee = null, parcelleCode = null, boundbox = null) => {
        return new Promise<FeatureCollection>((resolve, reject) => {
            let urlParams = '?format=geojson';
            if(codeInsee) urlParams += '&commune=' + codeInsee;
            if(parcelleCode) urlParams += '&parcelle=' + parcelleCode;
            if(boundbox) urlParams += '&boundbox=' + boundbox;
            fetch(process.env.REACT_APP_API_URL+'/tools/geo/documenturbanisme' + urlParams)
                .then((response) => {
                    if(response.status >= 400) response.json().then((error) => reject(error));
                    else resolve(response.json() as any as FeatureCollection);
                });
        });
    }

    const getZoneUrbaineGeoJSON = (codeInsee = null, parcelleCode = null, boundbox = null) => {
        return new Promise<FeatureCollection>((resolve, reject) => {
            let urlParams = '?format=geojson';
            if(codeInsee) urlParams += '&commune=' + codeInsee;
            if(parcelleCode) urlParams += '&parcelle=' + parcelleCode;
            if(boundbox) urlParams += '&boundbox=' + boundbox;
            fetch(process.env.REACT_APP_API_URL+'/tools/geo/zoneurbaine' + urlParams)
                .then((response) => {
                    if(response.status >= 400) response.json().then((error) => reject(error));
                    else resolve(response.json() as any as FeatureCollection);
                });
        });
    }

    const getInfoUrbanismeGeoJSON = (codeInsee = null, parcelleCode = null, boundbox = null) => {
        return new Promise<FeatureCollection>((resolve, reject) => {
            let urlParams = '?format=geojson';
            if(codeInsee) urlParams += '&commune=' + codeInsee;
            if(parcelleCode) urlParams += '&parcelle=' + parcelleCode;
            if(boundbox) urlParams += '&boundbox=' + boundbox;
            fetch(process.env.REACT_APP_API_URL+'/tools/geo/infourbanisme' + urlParams)
                .then((response) => {
                    if(response.status >= 400) response.json().then((error) => reject(error));
                    else resolve(response.json() as any as FeatureCollection);
                })
                .catch((error) => reject(error));
        });
    }
    
    const getCadastreGeoJSONByReference = (codeInsee, prefixe, section, numero) => {
        return new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL+'/tools/geo/cadastre/' + codeInsee + '/' + prefixe + '/' + section + '/' + numero)
                .then((response) => {
                    if(response.status >= 400) response.json().then((error) => reject(error));
                    else resolve(response.json());
                });
        });
    }

    const getReferentielValues = (type) => {
        return new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL+'/tools/referentiel/' + type)
                .then((response) => {
                    if(response.status >= 400) response.json().then((error) => reject(error));
                    else resolve(response.json());
                });
        });
    }

    const checkEligibilite = (brouillon: Brouillon) => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(brouillon)
            };
            
            fetch(process.env.REACT_APP_API_URL+'/tools/brouillon/check', requestOptions)
                .then((response) => {
                    if(response.status >= 400) response.json().then((error) => reject(error));
                    else resolve(response.json());
                });
        });
    }

    const createBrouillon = (datas) => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(datas)
            };
            
            fetch(process.env.REACT_APP_API_URL+'/tools/brouillon/create', requestOptions)
                .then((response) => {
                    if(response.status >= 400) response.json().then((error) => reject(error));
                    else resolve(response.json());
                });
        });
    }

    const transmissionPartenaires = (datas: any) => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(datas)
            };
            
            fetch(process.env.REACT_APP_API_URL+'/tools/contact/transmission/partenaires', requestOptions)
                .then((response) => {
                    if(response.status >= 400) response.json().then((error) => reject(error));
                    else resolve(response.json());
                });
        });
    }

    const login = (username: string, password: string) => {
        return new Promise<any>((resolve, reject) => {
            const requestOptions = {
                username: username,
                password: password
            };
            
            api.post('/login', requestOptions)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    return {
        getRegionsGeoJSON,
        getDepartementsGeoJSON,
        getCommunesGeoJSON,
        getCadastreGeoJSON,
        getMutationGeoJSON,
        getDocumentUrbanismeGeoJSON,
        getZoneUrbaineGeoJSON,
        getInfoUrbanismeGeoJSON,
        getCadastreGeoJSONByReference,
        getReferentielValues,
        checkEligibilite,
        createBrouillon,
        transmissionPartenaires,
        login
    }
}

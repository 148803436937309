import { AxiosError, AxiosResponse } from "axios";
import { api } from "../helpers/api";
import { Brouillon } from "../models/Brouillon";
import { Dossier } from "../models/Dossier";

export const useDossier = () => {
    const getDossierByToken = (token: string) => {
        return new Promise<Dossier>((resolve, reject) => {
            api.get('/tools/dossier/' + token)
                .then((response: AxiosResponse<Dossier>) => resolve(response?.data))
                .catch((error: AxiosError) => reject(error?.response?.data));
        });
    }

    const putReponseByToken = (token: string, reponses: any[]) => {
        return new Promise<any>((resolve, reject) => {
            api.put('/tools/dossier/reponse/' + token, reponses)
                .then((response: AxiosResponse<any>) => resolve(response?.data))
                .catch((error: AxiosError) => reject(error?.response?.data));
        });
    }

    const getDossier = (idDossier) => {
        return new Promise<Dossier>((resolve, reject) => {
            api.get('/dossier/' + idDossier)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    
    const addDocumentByToken = (token: string, document: any, typeId: number) => {
        return new Promise<any>((resolve, reject) => {
            const formData = new FormData();
            formData.append('document', document);
            formData.append('typeId', typeId.toString());
            api.post('/tools/dossier/document/' + token, formData)
                .then((response: AxiosResponse<any>) => resolve(response?.data))
                .catch((error: AxiosError) => reject(error?.response?.data));
        });
    }

    return {
        getDossierByToken,
        putReponseByToken,
        getDossier,
        addDocumentByToken
    }
}

//React
import { useEffect } from "react";

//Hooks
import { useMapInfo } from "../hooks/useMapInfo";
import { useMap } from "react-leaflet";

function MapAction() {
    const map = useMap();
    const { mapInfo } = useMapInfo();

    useEffect(() => {
        if(mapInfo.centerCoordinates?.latitude && mapInfo.centerCoordinates?.longitude && mapInfo.centerCoordinates?.zoom) map.flyTo([mapInfo.centerCoordinates.latitude, mapInfo.centerCoordinates.longitude], mapInfo.centerCoordinates.zoom);
    }, [mapInfo.centerCoordinates]);

    useEffect(() => {
        if(mapInfo.maxBounds) {
            map.setMaxBounds(mapInfo.maxBounds);
            map.flyTo([mapInfo.centerCoordinates.latitude, mapInfo.centerCoordinates.longitude], mapInfo.centerCoordinates.zoom);
        }
    }, [mapInfo.maxBounds]);
    
    return null;
}

export default MapAction;
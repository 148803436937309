import { useDispatch, useSelector } from "react-redux";
import { selectMapInfo, setMapCoordinates, setMapAddress, addMapParcelle, setMapParcelles, setBrouillon, setInvitationToken, setMapCadastres, setMapSelectedCadastres, setMapBounds } from "../store/mapInfoSlice";

export const useMapInfo = () => {
    const dispatch = useDispatch();
    const mapInfo = useSelector(selectMapInfo);

    const centerMap = (centerCoordinates: any, zoom = 18) => {
        dispatch(setMapCoordinates({...centerCoordinates, zoom: zoom}));
    }

    const setMaxBounds = (centerCoordinates: any, distance = 10) => {
        const earthRadiusKm = 111; // Rayon approximatif de la Terre pour la conversion en degrés de latitude
  
        // Calcul des décalages en latitude et longitude
        const deltaLat = distance / earthRadiusKm;
        const deltaLng = distance / (earthRadiusKm * Math.cos((centerCoordinates.latitude * Math.PI) / 180));
        
        // Calcul des coordonnées des coins sud-ouest et nord-est
        const latMin = centerCoordinates.latitude - deltaLat;
        const latMax = centerCoordinates.latitude + deltaLat;
        const lngMin = centerCoordinates.longitude - deltaLng;
        const lngMax = centerCoordinates.longitude + deltaLng;
        
        const bounds = [
            [latMin, lngMin], // Coin sud-ouest
            [latMax, lngMax]  // Coin nord-est
        ];
        
        dispatch(setMapBounds(bounds));
    }

    const setStoreAddress = (address: any) => {
        dispatch(setMapAddress(address));
        centerMap(address.coordinates);
    }

    const addStoreParcelle = (parcelle) => {
        dispatch(addMapParcelle(parcelle));
    }

    const removeStoreParcelle = (parcelleCode) => {
        const newParcelles = mapInfo.parcelles.filter((parcelle) => parcelle.code !== parcelleCode);
        dispatch(setMapParcelles(newParcelles));
    }

    const cleanStoreParcelle = () => {
        dispatch(setMapParcelles([]));
    }

    const setStoreBrouillon = (brouillon) => {
        dispatch(setBrouillon(brouillon));
    }

    const setStoreInvitationToken = (invitationToken) => {
        dispatch(setInvitationToken(invitationToken));
    }

    const setStoreCadastres = (cadastres) => {
        dispatch(setMapCadastres(cadastres));
    }

    const setStoreSelectedCadastres = (cadastres) => {
        dispatch(setMapSelectedCadastres(cadastres));
    }

    return {
        mapInfo,
        centerMap,
        setMaxBounds,
        setStoreAddress,
        addStoreParcelle,
        removeStoreParcelle,
        cleanStoreParcelle,
        setStoreBrouillon,
        setStoreInvitationToken,
        setStoreCadastres,
        setStoreSelectedCadastres
    }
}

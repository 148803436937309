import { combineReducers, configureStore } from '@reduxjs/toolkit'
import mapInfoReducer from './mapInfoSlice'
import mapUtilReducer from './mapUtilSlice'
import cartoReducer from './cartoSlice'

export const store = configureStore({
  reducer: combineReducers({
      mapInfo: mapInfoReducer,
      mapUtil: mapUtilReducer,
      carto: cartoReducer
    }),
})


import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDossier } from "../../../hooks/useDossier";
import { Dossier } from "../../../models/Dossier";
import { GeoJSON, Pane } from 'react-leaflet';

//Bootstrap
import { Card, InputGroup, Button, Row, Col, Badge, Accordion, Spinner } from "react-bootstrap";
import { useMapInfo } from "../../../hooks/useMapInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faDownload, faEye, faEyeSlash, faHouse, faLocationCrosshairs, faSquare, faSquareFull } from "@fortawesome/free-solid-svg-icons";
import { useCarto } from "../../../hooks/useCarto";
import { useApi } from "../../../hooks/useApi";
import { Feature, FeatureCollection } from "geojson";
import { latLng, Layer } from "leaflet";
import L from "leaflet";

//Params

export const CadastreCard = () => {
    /**
     * Hooks
     */
    const { getDossier } = useDossier();
    const { mapInfo, centerMap } = useMapInfo();
    const { carto } = useCarto();
    const { getDocumentUrbanismeGeoJSON, getZoneUrbaineGeoJSON, getInfoUrbanismeGeoJSON } = useApi();

    /**
     * States
     */
    const [ layerKey, setLayerKey ] = useState(0); //Pour forcer le rechargement du layer cadastre (voir useEffect)
    const [ loadDocumentUrbanisme, setLoadDocumentUrbanisme ] = useState(false);
    const [ documentUrbanisme, setDocumentUrbanisme ] = useState(null);
    const [ loadZoneUrbaine, setLoadZoneUrbaine ] = useState(false);
    const [ zoneUrbaine, setZoneUrbaine ] = useState(null);
    const [ loadInfoUrbanisme, setLoadInfoUrbanisme ] = useState(false);
    const [ infoUrbanisme, setInfoUrbanisme ] = useState(null);
    const [ showedFeatures, setShowedFeatures ] = useState<FeatureCollection>(null);
    const [ accordionActiveKey, setAccordionActiveKey ] = useState(null);

    /**
     * Constants
     */
    const couleurs = [
        '#FFA07A', '#20B2AA', '#87CEFA', '#CD5C5C', '#FFD700', '#FF6347', '#00FF7F', '#00FA9A', '#FF69B4', '#778899',
        '#FF6347', '#FF4500', '#FFD700', '#FFA500', '#FF8C00', '#FF7F50', '#FF6347', '#FF4500', '#FFD700', '#FFA500',
        '#FFC0CB', '#FFB6C1', '#FF69B4', '#FF1493', '#DB7093', '#C71585', '#FFA07A', '#FA8072', '#E9967A', '#F08080',
        '#FF8C00', '#FF7F50', '#FF6347', '#FF4500', '#FFD700', '#FFA500', '#FF8C00', '#FF7F50', '#FF6347', '#FF4500'
    ];
    
    /**
     * useEffect
     */
    useEffect(() => {
        setLayerKey(layerKey + 1);
    }, [showedFeatures, carto.selectedParcelle]);


    useEffect(() => {
        if(carto.selectedParcelle) {
            setShowedFeatures(null);
            setDocumentUrbanisme(null);
            setZoneUrbaine(null);
            setInfoUrbanisme(null);
            setAccordionActiveKey(null);
            
            //Load document urbanisme
            setLoadDocumentUrbanisme(true);
            getDocumentUrbanismeGeoJSON(null, carto.selectedParcelle.properties.code, null)
                .then((documentUrbanisme) => {
                    setDocumentUrbanisme(documentUrbanisme);
                    setLoadDocumentUrbanisme(false);
                })
                .catch((error) => {
                    console.error(error); //TODO: Gérer l'erreur
                });
                
            //Load zone urbaine
            setLoadZoneUrbaine(true);
            getZoneUrbaineGeoJSON(null, carto.selectedParcelle.properties.code, null)
                .then((zoneUrbaine) => {
                    setZoneUrbaine(zoneUrbaine);
                    setLoadZoneUrbaine(false);
                })
                .catch((error) => {
                    console.error(error); //TODO: Gérer l'erreur
                });
            
            //Load info urbanisme
            setLoadInfoUrbanisme(true);
            getInfoUrbanismeGeoJSON(null, carto.selectedParcelle.properties.code, null)
                .then((infoUrbanisme) => {
                    setInfoUrbanisme(infoUrbanisme);
                    setLoadInfoUrbanisme(false);
                })
                .catch((error) => {
                    console.error(error); //TODO: Gérer l'erreur
                });
        }
    }, [carto.selectedParcelle]);

    /**
     * Handlers
     */
    const handleShowHideFeature = (feature) => {
        if(showedFeatures == null) setShowedFeatures({type: 'FeatureCollection', features: [feature]});
        else {
            let newShowedFeatures = {...showedFeatures};
            if(newShowedFeatures.features.includes(feature)) {
                newShowedFeatures.features = newShowedFeatures.features.filter((f) => {
                    return f.properties.id != feature.properties.id;
                });
            } else {
                newShowedFeatures.features = [...newShowedFeatures.features, feature];
            }
            setShowedFeatures(newShowedFeatures);
        }
    }

    /**
     * Functions
     */
    const getLayerColor = (feature) => {
        let index = 0;
        let startIndex = 0;
        //Get index of feature in zoneUrbaine
        if(zoneUrbaine) {
            let zoneUrbaineIndex = zoneUrbaine.features.findIndex((zone) => {
                return zone.properties.id == feature.properties.id;
            });
            if(zoneUrbaineIndex != -1) index = zoneUrbaineIndex;
            startIndex = zoneUrbaine.features.length;
        }
        //Get index of feature in infoUrbanisme
        if(infoUrbanisme) {
            let infoUrbanismeIndex = infoUrbanisme.features.findIndex((info) => {
                return info.properties.id == feature.properties.id;
            });
            if(infoUrbanismeIndex != -1) index = infoUrbanismeIndex+startIndex;
            startIndex = startIndex + infoUrbanisme.features.length;
        }

        let color = couleurs[index];
        return color;
    }

    const getLayerStyle = (feature) => {
        return {
            color: getLayerColor(feature),
            weight: 2
        };
    }

    /**
     * Render
     */
    if(!carto.selectedParcelle) return null;

    return (
        <div>
            <Card className="card-cadastre mt-2" style={{ maxWidth: '500px' }}>
                <Card.Body>
                    <Row>
                        <Col className="mb-0">
                            Parcelle
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-0 h3">
                            {carto.selectedParcelle.properties.code}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-3">
                            {carto.selectedParcelle.properties.prefixe} / {carto.selectedParcelle.properties.section} / {carto.selectedParcelle.properties.numero} - {carto.selectedParcelle.properties.contenance} m²
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Accordion activeKey={accordionActiveKey} onSelect={(key) => setAccordionActiveKey(key)}>
                                <Accordion.Item eventKey="mutation">
                                    <Accordion.Header>
                                        Mutations ({carto.mutations?.features?.filter((feature) => {
                                            return feature.properties.cadastre.code == carto.selectedParcelle.properties.code;
                                        }).length})
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {carto.mutations.features.filter((feature) => {
                                            return feature.properties.cadastre.code == carto.selectedParcelle.properties.code;
                                        }).map((feature) => {
                                            return (
                                                <div key={feature.properties.id} style={{ width: '100%', borderBottom: '1px dashed black', paddingBottom: '10px' }}>
                                                    <div className="d-flex justify-content-between">
                                                        <span className="fw-bold fs-5">{feature.properties.nature}</span>
                                                        <span className="fs-5">{feature.properties.valeurFonciere.toLocaleString()} €</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <span><FontAwesomeIcon icon={faCalendar} /> {feature.properties.dateMutation?new Date(feature.properties.dateMutation).toLocaleDateString('fr-FR'):null}</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-2">
                                                        <span>{feature.properties.typeLocal}</span>
                                                        <span><FontAwesomeIcon icon={faHouse} /> {feature.properties.surfaceBatiment?feature.properties.surfaceBatiment.toLocaleString():'-'} m²</span>
                                                        <span><FontAwesomeIcon icon={faSquare} /> {feature.properties.surfaceTerrain?feature.properties.surfaceTerrain.toLocaleString():'-'} m²</span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="documents">
                                    <Accordion.Header>
                                        {loadDocumentUrbanisme ? (
                                            <div className="d-flex align-items-center">
                                                Documents d'urbanisme&nbsp;<Spinner animation="border" role="status" size="sm" className="ml-2"></Spinner>
                                            </div>
                                        ) : (
                                            <>
                                                Documents d'urbanisme ({documentUrbanisme?(documentUrbanisme.features.length>0?documentUrbanisme.features[0].properties.files?.length:0):0})
                                            </>
                                        )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {documentUrbanisme && documentUrbanisme.features.map((document) => (
                                            <div key={document.properties.id}>
                                                {document.properties.title}
                                                <ul>
                                                    {document.properties.files.map((file) => (
                                                        <li key={file.id}>
                                                            {file.path} - {file.title} <a href={file.urlSource} target="_blank"><FontAwesomeIcon icon={faDownload} color="blue" style={{ cursor: 'pointer' }} /></a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="zoneUrbaine">
                                    <Accordion.Header>
                                        {loadZoneUrbaine ? (
                                            <div className="d-flex align-items-center">
                                                Zonage&nbsp;<Spinner animation="border" role="status" size="sm" className="ml-2"></Spinner>
                                            </div>
                                        ) : (
                                            <>
                                                Zonage ({zoneUrbaine?zoneUrbaine.features.length:0})
                                            </>
                                        )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {zoneUrbaine && zoneUrbaine.features.map((zone) => (
                                            <div key={zone.properties.id}>
                                                <strong>{zone.properties.label}</strong>{zone.properties.labelong?' - '+zone.properties.labelong:''} <FontAwesomeIcon icon={showedFeatures?(showedFeatures.features.find((showedFeature) => showedFeature.properties.id == zone.properties.id)?faEyeSlash:faEye):faEye} color={getLayerColor(zone)} style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handleShowHideFeature(zone)} />
                                            </div>
                                        ))}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="prescription">
                                    <Accordion.Header>
                                        {loadInfoUrbanisme ? (
                                            <div className="d-flex align-items-center">
                                                Prescriptions&nbsp;<Spinner animation="border" role="status" size="sm" className="ml-2"></Spinner>
                                            </div>
                                        ) : (
                                            <>
                                                Prescriptions ({infoUrbanisme?infoUrbanisme.features.filter((feature) => feature.properties.typeInfo == 'Prescription' ).length:0})
                                            </>
                                        )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {infoUrbanisme && infoUrbanisme.features.filter((feature) => feature.properties.typeInfo == 'Prescription' ).map((info) => (
                                            <div key={info.properties.id}>
                                                <strong>{info.properties.typeInfo}</strong> - {info.properties.labelong} <FontAwesomeIcon icon={showedFeatures?(showedFeatures.features.find((showedFeature) => showedFeature.properties.id == info.properties.id)?faEyeSlash:faEye):faEye} color={getLayerColor(info)} style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handleShowHideFeature(info)} />
                                            </div>
                                        ))}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Pane name="selectedParcelles" style={{zIndex: 500}}>
                {carto.selectedParcelle && (
                    <GeoJSON key={layerKey+10000} data={carto.selectedParcelle} style={{
                        color: '#0000FF',
                        dashArray: '5, 5',
                        fillOpacity: 0
                    }} />
                )}
            </Pane>
            <Pane name="urbanisme" style={{zIndex: 300}}>
                <GeoJSON key={layerKey} data={showedFeatures} style={getLayerStyle} pointToLayer={(feature, latLng) => L.circleMarker(latLng)} />
            </Pane>
        </div>
    );
}

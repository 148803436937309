//Bootstrap
import { Card, InputGroup, Button, Row, Col, Form } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

//Hooks
import { useDataGouv } from "../hooks/useDataGouv";

//React
import React from 'react';
import { useEffect, useState } from "react";

//Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useMapInfo } from "../hooks/useMapInfo";
import { set } from "react-hook-form";
import logo from '../assets/logo.png';
import { useApi } from "../hooks/useApi";

function CardAddress() {
    /**
     * Hooks
     */
    const { apiAddress } = useDataGouv();
    const { mapInfo, setStoreAddress, addStoreParcelle } = useMapInfo();
    const { getCadastreGeoJSON } = useApi();

    /**
     * States
     */
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCadastre, setIsLoadingCadastre] = useState(false);
    const [options, setOptions] = useState<any>([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [defaultAddress, setDefaultAddress] = useState(null);
    const [searchMode, setSearchMode] = useState('address');

    /**
     * useEffect
     */
    useEffect(() => {
        if (mapInfo.address?.label && !mapInfo.address?.coordinates?.latitude) {
            setTimeout(() => {
                setDefaultAddress(mapInfo.address?.label);
            }, 1000);
            
            setIsLoading(true);
        
            apiAddress(mapInfo.address?.label, 1)
                .then((response: any) => {
                    var options = response.features.map((feature) => {
                        return {
                            id: feature.properties.id,
                            label: feature.properties.label,
                            postcode: feature.properties.postcode,
                            housenumber: feature.properties.housenumber,
                            street: feature.properties.street,
                            city: feature.properties.city,
                            citycode: feature.properties.citycode,
                            score: feature.properties.score,
                            coordinates: {
                                latitude: feature.geometry.coordinates[1],
                                longitude: feature.geometry.coordinates[0]
                            }
                        }
                    });

                    if(options.length === 1) setStoreAddress(options[0]);
                    
                    setIsLoading(false);
                })
                .catch((error) => {
                    
                });
        }
    }, [mapInfo.address?.label]);

    /**
     * Handlers
     */
    const handleSearch = (query) => {
        setIsLoading(true);
        
        apiAddress(query, 5)
            .then((response: any) => {
                var options = response.features.map((feature) => {
                    return {
                        id: feature.properties.id,
                        label: feature.properties.label,
                        postcode: feature.properties.postcode,
                        housenumber: feature.properties.housenumber,
                        street: feature.properties.street,
                        city: feature.properties.city,
                        citycode: feature.properties.citycode,
                        score: feature.properties.score,
                        coordinates: {
                            latitude: feature.geometry.coordinates[1],
                            longitude: feature.geometry.coordinates[0]
                        }
                    }
                });

                setOptions(options);
                
                setIsLoading(false);
            })
            .catch((error) => {
                
            });
    }

    const handleChange = (selected) => {
        setSelectedOption(selected[0]);
        handleClick(selected[0]);
    }

    const handleChangeByRef = (selected) => {
        setSelectedOption(selected[0]);
    }

    const handleClick = (selectedValue = null) => {
        //On centre la carte sur l'adresse sélectionnée
        setStoreAddress(selectedValue??selectedOption);
    }

    const handleClickByRef = () => {
        setIsLoadingCadastre(true);

        const prefixe = (document.getElementById('prefixe') as HTMLInputElement).value;
        const section = (document.getElementById('section') as HTMLInputElement).value;
        const numero = (document.getElementById('numero') as HTMLInputElement).value;

        if(prefixe.length === 0 || section.length === 0 || numero.length === 0) return;

        getCadastreGeoJSON(selectedOption.citycode)
            .then((response: any) => {
                //On récupère le cadastre correspondant au prefixe, section et numéro
                var cadastre = response.features.filter((feature) => {
                    if(feature.properties.section == 'BC' && feature.properties.numero == 668) console.log(feature);
                    return parseInt(feature.properties.prefixe) === parseInt(prefixe) && feature.properties.section === section && parseInt(feature.properties.numero) === parseInt(numero);
                })[0];

                if(!cadastre) {
                    setIsLoadingCadastre(false);
                    return;
                }

                //On ajoute le cadastre dans les parcelles sélectionnées
                let isAlreadyPresent = false;
                mapInfo.parcelles.forEach((parcelle) => {
                    if(parcelle.code == cadastre.properties.code) isAlreadyPresent = true;
                });

                //Si elle n'est pas présente, on l'ajoute
                if(!isAlreadyPresent) {
                    addStoreParcelle({
                            code: cadastre.properties.code,
                            commune: cadastre.properties.commune,
                            surface: cadastre.properties.contenance,
                            prefixe: cadastre.properties.prefixe,
                            section: cadastre.properties.section,
                            numero: cadastre.properties.numero,
                            coordinates: cadastre.geometry.coordinates[0],
                            feature: cadastre
                        });
                }

                //On centre la carte sur le cadastre
                setStoreAddress({
                    ...selectedOption,
                    coordinates: {
                        latitude: cadastre.geometry.coordinates[0][0][0][1],
                        longitude: cadastre.geometry.coordinates[0][0][0][0]
                    }
                });

                setIsLoadingCadastre(false);
            })
            .catch((error) => {
                
            });
    }

    /**
     * Functions
     */
    const filterBy = () => true;
    
    /**
     * Render
     */
    return (
        <>
            {defaultAddress !== null && 
                <Card className="card-address">
                    <Card.Body>
                        <Row>
                            <Col className="mb-2 p-0 text-center">
                                <img src={logo} alt="logo" height="50" />
                            </Col>
                        </Row>
                        {searchMode === 'address' ? (
                            <>
                                <Row>
                                    <Col>
                                        <InputGroup className="card-address-inputgroup">
                                            <AsyncTypeahead
                                                filterBy={filterBy}
                                                id="search-address"
                                                isLoading={isLoading}
                                                labelKey="label"
                                                minLength={4}
                                                onSearch={handleSearch}
                                                onChange={handleChange}
                                                options={options}
                                                placeholder="Entrez une adresse"
                                                positionFixed={true}
                                                defaultInputValue={defaultAddress||''}
                                                renderMenuItemChildren={(option, props) => (
                                                    <>
                                                        <span>{(option as any).label}</span>
                                                    </>
                                                )}
                                            />
                                            <Button variant="primary" id="btn-adress" onClick={handleClick} disabled={!selectedOption}>
                                                <FontAwesomeIcon icon={faSearch} />
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex flex-row-reverse">
                                        <Button variant="link"  className="p-0 " onClick={() => setSearchMode('cadastre')}><small>Rechercher par réf. cadastrale</small></Button>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <>
                                <Row>
                                    <Col>
                                        <AsyncTypeahead
                                                filterBy={filterBy}
                                                id="search-address"
                                                isLoading={isLoading}
                                                labelKey="label"
                                                minLength={4}
                                                onSearch={handleSearch}
                                                onChange={handleChangeByRef}
                                                options={options}
                                                placeholder="Entrez une commune"
                                                positionFixed={true}
                                                defaultInputValue={''}
                                                className="mb-1"
                                                renderMenuItemChildren={(option, props) => (
                                                    <>
                                                        <span>{(option as any).label}</span>
                                                    </>
                                                )}
                                            />
                                        <InputGroup className="card-address-inputgroup">
                                            <Form.Control
                                                type="text"
                                                placeholder="000"
                                                id="prefixe"
                                            />
                                            <Form.Control
                                                type="text"
                                                placeholder="AA"
                                                id="section"
                                            />
                                            <Form.Control
                                                type="text"
                                                placeholder="123"
                                                id="numero"
                                            />
                                            <Button variant="primary" id="btn-adress" onClick={handleClickByRef} disabled={!selectedOption || isLoadingCadastre}>
                                                {isLoadingCadastre ? <div className="spinner-border spinner-border-sm" role="status"></div> : <FontAwesomeIcon icon={faSearch} />}
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex flex-row-reverse">
                                        <Button variant="link"  className="p-0 " onClick={() => setSearchMode('address')}><small>Rechercher par adresse</small></Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Card.Body>
                </Card>
            }
        </>
    );
}

export default CardAddress;
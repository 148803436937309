//React
import { useEffect, useState } from "react";

//Hooks
import { useApi } from "../../../hooks/useApi";
import { useMapInfo } from "../../../hooks/useMapInfo";
import { useMapUtil } from "../../../hooks/useMapUtil";

//Leaflet & geo
import L from "leaflet";
import { Circle, GeoJSON, Marker, Rectangle, useMapEvents } from 'react-leaflet';
import { LatLng, LatLngBounds, map } from "leaflet";
import { geoExpandBounds } from "../../../helpers/GeoHelper";
import { useCarto } from "../../../hooks/useCarto";

//params
interface GeoDataLayersParams {
    onCadastreClick?: (cadastre) => void;
}

function GeoDataLayers({onCadastreClick}: GeoDataLayersParams) {
    /**
     * Hooks
     */
    const { mapInfo } = useMapInfo();
    const { carto, setMutations } = useCarto();
    const { createLoader, deleteLoader, isLoader } = useMapUtil();
    const { getMutationGeoJSON } = useApi();
    const mapEvent = useMapEvents({
        //Detect zoom change
        zoomend: () => {
            setActualZoom(mapEvent.getZoom());
        },
        //Detect move change
        moveend: () => {
            setCenter(mapEvent.getCenter());
            setBounds(mapEvent.getBounds());
        }
    });

    /**
     * States
     */
    const [layerKey, setLayerKey] = useState(1000); //Pour forcer le rechargement du layer cadastre (voir useEffect)
    const [actualZoom, setActualZoom] = useState(0);
    const [center, setCenter] = useState<LatLng>(null);
    const [bounds, setBounds] = useState<LatLngBounds>(null);
    const [mutationShapes, setMutationShapes] = useState(null);

    /**
     * useEffects
     */
    useEffect(() => {
        if(mapInfo.address?.citycode) {
            
        }
    }, [mapInfo.address]);

    useEffect(() => {
        setLayerKey(layerKey + 1);
    }, [carto.mutations]);

    useEffect(() => {
        if(actualZoom >= 17 && !isLoader('mutation')) {
            createLoader('mutation', 'Chargement des mutations...');
            //TODO: vérifier que le nouveau bounds n'est pas inclu dans le bounds des cadastre déjà chargés
            
            let newBounds = geoExpandBounds(bounds, 300);

            getMutationGeoJSON(null, newBounds.toBBoxString())
                .then((data) => {
                    //On fusionne les features pour éviter les doublons
                    let features = carto.mutations==null?[]:carto.mutations.length==0?[]:carto.mutations.features;
                    
                    data.features.forEach((feature) => {
                        let isAlreadyPresent = false;
                        for(let i = 0; i < features.length; i++) {
                            if(features[i].properties.cadastre.code == feature.properties.cadastre.code) isAlreadyPresent = true;
                        }
                        if(!isAlreadyPresent) {
                            features = [...features, feature];
                        }
                    });
                    
                    setMutations({
                        type: "FeatureCollection",
                        features: features
                    });
                    
                    //TODO: supprimer les cadastres trop éloignés
                    deleteLoader('mutation');
                })
                .catch((error) => {
                    deleteLoader('mutation');
                    console.log(error);
                });
        }
    }, [actualZoom, center, bounds]);

    useEffect(() => {
        if(carto.mutations && mapInfo.cadastres?.features) {
            //On récupère la géométrie des cadastres pour chaque mutation
            if(carto.mutations.features?.length > 0) {
                let newMutationShapes = {
                    type: "FeatureCollection",
                    features: []
                };
                carto.mutations.features.forEach((feature) => {
                    mapInfo.cadastres.features.forEach((cadastre) => {
                        if(cadastre.properties.code == feature.properties.cadastre.code) {
                            newMutationShapes.features.push({
                                type: "Feature",
                                properties: feature.properties,
                                geometry: cadastre.geometry
                            });
                        }
                    });
                });

                setMutationShapes(newMutationShapes);
            }

        }
    }, [carto.mutations, mapInfo.cadastres]);

    if(!mapInfo.cadastres || actualZoom < 17) return (
        <>
        </>
    );

    /**
     * Handlers
     */
    const handleClickOnMutation = (e) => {
        if(onCadastreClick) onCadastreClick(e.target.feature);
    }

    const onEachMutation = (feature, layer) => {
        layer.on({
            click: handleClickOnMutation
        });
    }
    
    return (
        <>
            <GeoJSON
                key={layerKey}
                data={carto.mutations}
                pointToLayer={(feature, latlng) => {
                    //Retourner un cercle de 1px avec un tooltip
                    let circle = L.circle(latlng, {
                        radius: 1,
                        fillColor: "#ffffff",
                        fillOpacity: 1,
                        color: "#ffffff",
                    });

                    if(feature.properties.valeurFonciere) {
                        let tooltip = L.tooltip({
                            direction: 'top',
                            permanent: true,
                            opacity: 1,
                        }).setContent(feature.properties.valeurFonciere.toLocaleString() + " €");

                        tooltip.setLatLng(latlng);

                        return tooltip;
                    }
                    else return null;
                }}
                onEachFeature={onEachMutation}/>
        </>
    );
}

export default GeoDataLayers;
import { useParams } from "react-router-dom";
import Map from "../../sharedComponents/Map";
import { useEffect, useState } from "react";
import { useMapInfo } from "../../hooks/useMapInfo";
import { useMapUtil } from "../../hooks/useMapUtil";
import { api } from "../../helpers/api";
import { useDossier } from "../../hooks/useDossier";
import { Dossier } from "../../models/Dossier";
import { DossierCard } from "./components/DossierCard";
import MapCadastreLayer from "../../sharedComponents/MapCadastreLayer";
import MapMutationLayer from "./components/MapMutationLayer";
import GeoDataLayers from "./components/GeoDataLayers";
import { CadastreCard } from "./components/CadastreCard";
import { useCarto } from "../../hooks/useCarto";
import { useDataGouv } from "../../hooks/useDataGouv";
import { FeatureCollection } from "geojson";
import ModalLogin from "../../sharedComponents/ModalLogin";
import { useMap } from "react-leaflet";
import { useApi } from "../../hooks/useApi";
import { get } from "http";


function DCarto() {
    const NB_KM_MAX = 1;
    
    /**
     * Hooks
     */
    const routeParams = useParams();
    const { idDossier, uToken } = routeParams;
    const { mapInfo, centerMap, setStoreSelectedCadastres, setMaxBounds } = useMapInfo();
    const { createLoader, deleteLoader } = useMapUtil();
    const { getDossier } = useDossier();
    const { setSelectedParcelle } = useCarto();
    const { apiAddress } = useDataGouv();
    const { getCadastreGeoJSON } = useApi();

    /**
     * States
     */
    const [ dossier, setDossier ] = useState<Dossier>(null);
    const [ layers, setLayers ] = useState([]);
    
    /**
     * useEffect
     */
    useEffect(() => {
        if(uToken) {
            //On enregistre le token de l'utilisateur dans axios
            api.defaults.headers.common['Authorization'] = `Bearer ${uToken}`;

            //On charge les informations du dossier
            createLoader('dossier', 'Chargement des informations du dossier');
            getDossier(idDossier)
                .then((dossier) => {
                    setDossier(dossier);
                    if(dossier.latitude && dossier.longitude) {
                        //On limite la carte à 1km de rayon
                        setMaxBounds({
                            longitude: dossier.longitude,
                            latitude: dossier.latitude
                        }, NB_KM_MAX);

                        //On centre la carte sur le dossier
                        centerMap({
                            longitude: dossier.longitude,
                            latitude: dossier.latitude
                        }, 19);
                    }
                    else {
                        //On centre la carte sur l'adresse du dossier
                        apiAddress((dossier.adresse?dossier.adresse!='INCONNUE'?dossier.adresse:'':'')+' '+dossier.codePostal+' '+dossier.commune, 1)
                            .then((data) => {
                                if(data.features?.length > 0) {
                                    let geometry = data.features[0].geometry as any;
                                    //On limite la carte à 1km de rayon
                                    setMaxBounds({
                                        longitude: geometry.coordinates[0],
                                        latitude: geometry.coordinates[1]
                                    }, NB_KM_MAX);

                                    centerMap({
                                        longitude: geometry.coordinates[0],
                                        latitude: geometry.coordinates[1]
                                    }, 19);
                                }
                                else {
                                    //TODO: on affiche une erreur informant que l'adresse n'a pas été trouvée
                                }
                            })
                            .catch((error) => {
                                console.error(error); //TODO: Gérer l'erreur
                            });
                    }
                    deleteLoader('dossier');
                })
                .catch((error) => {
                    console.error(error); //TODO: Gérer l'erreur
                    deleteLoader('dossier');
                });
        }
    }, [uToken]);

    useEffect(() => {
        if(dossier && mapInfo.cadastres && mapInfo.cadastres.features?.length > 0) {
            //Ajout des parcelles du dossier sur la carte
            let features = [];
            dossier.parcelles.forEach((parcelle) => {
                //On récupère les informations de la parcelle dans le cadastre
                let feature = mapInfo.cadastres.features.filter((cadastre) => {
                    return cadastre.properties.prefixe == parcelle.prefixe && cadastre.properties.section == parcelle.section && cadastre.properties.numero == parcelle.numero;
                });

                //On ajoute la parcelle à la carte
                if(feature.length > 0) features = [...features, feature[0]];
            });

            //Si aucune parcelle n'a été trouvée, on récupère toutes les parcelles de la commune
            if(features.length == 0) {
                createLoader('cadastre', 'Recherche des parcelles du dossier');
                //On commence par récupérer le code insee de la commune
                apiAddress(dossier.codePostal+' '+dossier.commune, 1)
                    .then((data) => {
                        if(data.features?.length > 0) {                        
                            getCadastreGeoJSON(data.features[0].properties.citycode)
                                .then((data) => {
                                    deleteLoader('cadastre');

                                    //On ajoute les parcelles du dossier
                                    dossier.parcelles.forEach((parcelle) => {
                                        //On récupère les informations de la parcelle dans le cadastre
                                        let feature = data.features.filter((cadastre) => {
                                            return cadastre.properties.prefixe == parcelle.prefixe && cadastre.properties.section == parcelle.section && cadastre.properties.numero == parcelle.numero;
                                        });

                                        //On ajoute la parcelle à la carte
                                        if(feature.length > 0) features = [...features, feature[0]];
                                    });

                                    //On centre la carte sur la première parcelle
                                    if(features.length > 0) {
                                        setMaxBounds({
                                            longitude: features[0].geometry.coordinates[0][0][0][0],
                                            latitude: features[0].geometry.coordinates[0][0][0][1]
                                        }, NB_KM_MAX);

                                        centerMap({
                                            longitude: features[0].geometry.coordinates[0][0][0][0],
                                            latitude: features[0].geometry.coordinates[0][0][0][1]
                                        }, 19);
                                    }
                                })
                                .catch((error) => {
                                    console.error(error); //TODO: Gérer l'erreur
                                    deleteLoader('cadastre');
                                });
                        }
                    });
            }

            setStoreSelectedCadastres({
                type: "FeatureCollection",
                features: features
            });
        }
    }, [dossier, mapInfo.cadastres]);

    useEffect(() => {

    }, [layers]);

    /**
     * Handlers
     */
    const handleClickOnCadastre = (feature) => {
        setSelectedParcelle(feature);
    }

    /**
     * Functions
     */

    /**
     * Render
     */
    if (!idDossier) return null;

    return (
        <div>
            <Map>
                <div style={{ position: 'absolute', top: '10px', left: '10px', zIndex: 10000 }} className="d-flex justify-content-center flex-column">
                    <DossierCard dossier={dossier} />
                    <CadastreCard />
                </div>
                <MapCadastreLayer onCadastreClick={handleClickOnCadastre} />
                <GeoDataLayers />
                
            </Map>
            <ModalLogin urlPageToReload={'/carto/'+idDossier} />
        </div>
    );
}

export default DCarto;
